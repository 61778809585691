import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

GuestProtect.propTypes = {
  children: PropTypes.node,
};

function GuestProtect({ children }) {
  const userExists = localStorage.getItem("usertoken");
  const isLoading = false;
  const isAuthenticated = userExists;

  if (isLoading) {
    return <>loading...</>;
  }

  if (isAuthenticated) {
    return <Redirect to={"/app"} />;
  }

  return <>{children}</>;
}

export default GuestProtect;

import GuestProtect from "../components/Auth/GuestProtect";
import AuthProtect from "../components/Auth/AuthProtect";
import { Switch, Route, Redirect } from "react-router-dom";
import React, { Suspense, Fragment, lazy } from "react";
import LandingContainer from "../containers/LandingContainer";
import R from "../constants/Routes";

function RouteProgress(props) {
  return <Route {...props} />;
}

export function renderRoutes(routes = []) {
  return (
    <Suspense fallback={<></>}>
      <Switch>
        {routes.map((route, i) => {
          const Component = route.component;
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;

          return (
            <RouteProgress
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}

const routes = [
  // Others Routes
  {
    exact: true,
    guard: GuestProtect,
    layout: LandingContainer,
    path: R.LOGIN,
    component: lazy(() => import("../views/auth/LoginView")),
  },
  {
    exact: true,
    guard: AuthProtect,
    path: R.HOME,
    component: lazy(() => import("../views/home/HomePage")),
  },
  {
    exact: true,
    guard: AuthProtect,
    path: R.CREATE_MANUAL_COURSE,
    component: lazy(() => import("../views/course/CreateCourse")),
  },
  {
    exact: true,
    guard: AuthProtect,
    path: R.CREATE_GUIDED_COURSE,
    component: lazy(() => import("../views/course/CreateXCourse")),
  },
  {
    exact: true,
    guard: AuthProtect,
    path: R.CREATE_AUTO_COURSE,
    component: lazy(() => import("../views/course/CreateAutoCourse")),
  },
  {
    exact: true,
    guard: AuthProtect,
    path: R.CREATE_AUTO_COURSE + "/:id",
    component: lazy(() => import("../views/course/EditAutoCourse")),
  },
  {
    exact: true,
    guard: AuthProtect,
    path: R.EDIT_COURSE,
    component: lazy(() => import("../views/course/EditCourse")),
  },
  {
    exact: true,
    guard: AuthProtect,
    path: R.EDITOR,
    component: lazy(() => import("../views/editor/EditorPage")),
  },
  {
    exact: true,
    guard: AuthProtect,
    path: R.TOPIC,
    component: lazy(() => import("../views/topic/TopicPage")),
  },
  {
    exact: true,
    guard: AuthProtect,
    path: R.CONTENT,
    component: lazy(() => import("../views/content/ContentPage")),
  },
  {
    exact: true,
    guard: AuthProtect,
    path: R.CREATE_CONTENT,
    component: lazy(() => import("../views/content/CreateContent")),
  },
  {
    exact: true,
    guard: AuthProtect,
    path: R.EDIT_CONTENT,
    component: lazy(() => import("../views/content/EditContent")),
  },
  {
    exact: true,
    guard: AuthProtect,
    path: R.CIRCLE,
    component: lazy(() => import("../views/home/CirclePrompt")),
  },
  {
    exact: true,
    path: "/",
    component: () => <Redirect to={R.LOGIN} />,
  },
];

export default routes;
